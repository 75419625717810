
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: var(--cl-space-l);
}

.title {
  @extend %h3;
  margin-bottom: 2.6rem;
}

.description {
  margin-bottom: 0;
  max-width: 80ch;
}

.formFields {
  display: grid;
  gap: var(--cl-space-l);
}

.formFieldsTitle {
  font-weight: 600;
}

.formFieldsHelpText {
  max-width: 80ch;
}

.formField {
  display: grid;
  max-width: 36rem;
}

.noticeBannerContainer {
  max-width: 80ch;
}

.link {
  @extend %textLink;
}

.tooltipHeader {
  @extend %h5;
  margin-bottom: 2.5rem;
}

.tooltipSubheader {
  font-weight: 600;
}

.tooltipLink {
  font-weight: 600;
  text-decoration: underline;
}

.radioGroup {
  @include mq($until: s) {
    grid-auto-flow: row;
  }
}
