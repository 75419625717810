
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.tableCell {
  @extend %bodyMedium;
  padding: var(--cl-space-m) var(--tableHorizontalPadding);

  @include mq($until: m) {
    display: block;
    line-height: 1.4;
    padding: 0 var(--tableHorizontalPadding) 1.6rem;

    &[data-label]::before {
      content: attr(data-label);
      font-weight: 600;
      vertical-align: text-top;
    }

    &:first-of-type {
      padding-top: var(--cl-space-m);
    }

    &:last-of-type {
      padding-bottom: var(--cl-space-m);
    }
  }

  &.tableCellSmall {
    padding-bottom: var(--cl-space-s);
    padding-top: var(--cl-space-s);
  }
}

.actionCellInner {
  align-items: center;
  display: flex;
}

.linkWrapper {
  display: flex;
  min-width: 9.3rem;

  @include mq($until: m) {
    width: 100%;
  }

  a {
    flex: 1;
  }
}

.bold {
  font-weight: 600;
}

.completed {
  background-color: var(--cl-transparentTeal);
}

.inProgress {
  background-color: var(--cl-transparentBlue);
}

.requiresAction,
.unsuccessful {
  background-color: var(--cl-transparentCanadaLifeRed);
}

.icon {
  margin-right: var(--cl-space-s);
}

.link {
  outline: none;

  &:focus,
  &:focus-within {
    background-color: var(--cl-brightGold);
    box-shadow: 0 0 0 0.1em var(--cl-brightGold);

    .linkText,
    .icon {
      color: var(--cl-black);
    }
  }

  // Undo focus styles for mouse/pointer interaction
  &:focus:not(:focus-visible) {
    background-color: inherit;
    box-shadow: none;

    .linkText,
    .icon {
      color: var(--cl-canadaLifeRed);
    }
  }

  &:hover .linkText {
    background-position: 0 100%;
    background-size: 100% 1px;
  }
}

.linkText {
  @extend %textLink;
}

.size {
  color: var(--cl-canadaLifeGrey);
  text-decoration: underline;
}

.notice {
  border-radius: 0.2rem;
  color: var(--cl-white);
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1rem;
  padding: 0.3rem 0.5rem;
}

.deepBlueBackground {
  background: var(--cl-deepBlue);
}

.deepTealBackground {
  background: var(--cl-deepTeal);
}

.documentRow {
  .tableCell:first-of-type {
    @include mq($until: m) {
      padding-bottom: 1rem;
    }
  }

  .fileSize {
    color: var(--cl-canadaLifeGrey);
  }

  .dateCell {
    @include mq($until: m) {
      margin-left: 3.2rem;
    }

    @include mq(m) {
      text-align: end !important;
    }
  }
}

.productDetailsRow {
  .title,
  .value {
    position: static;

    @include mq(m) {
      width: 50%;
    }
  }

  .title {
    text-align: start;
    vertical-align: baseline;

    @include mq($until: m) {
      padding-bottom: 0;
    }
  }

  @include mq($until: m) {
    // Make sure tooltip doesn't overlap title text
    &.hasTooltip .title {
      padding-right: 4rem;
    }
  }

  .valueWrapper {
    display: flex;
    justify-content: space-between;
    position: static;
  }

  :global(#parent) {
    font-size: 2.2rem;
    height: 0.7em;
    width: 1em;

    @include mq($until: m) {
      position: absolute;
      right: var(--cl-space-m);
      top: var(--cl-space-m);
    }
  }

  :global(.toggleButton) {
    border-radius: 50%;
    color: var(--cl-canadaLifeRed);

    &:focus {
      background: var(--cl-brightGold);
      box-shadow: inset 0 0 0 2px var(--cl-brightGold),
        0 0 0 2px var(--cl-brightGold);
      color: var(--cl-black);
    }

    // Undo focus styles for mouse/pointer interaction
    &:focus:not(:focus-visible) {
      background-color: inherit;
      box-shadow: inherit;
      color: inherit;
    }
  }
}
