
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  @include clamp(gap, 2rem, clamp-vw-value(3rem), 3rem);
  display: grid;
}

.header {
  @include clamp(gap, 1rem, clamp-vw-value(1.5rem), 1.5rem);
  display: grid;
}

.title {
  @extend %h3;
  margin: 0;
}

.paragraph {
  margin-bottom: 0;
  margin-top: 0;
}

.actions {
  display: grid;
  gap: 2rem;
  justify-items: center;
}

.helpSection {
  display: flex;
  justify-content: center;
}

.helpButton {
  color: map-get($colors, "mediumRed");
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
}

.otpErrorMessage {
  color: map-get($colors, "errorRed");
  font-size: 1.5rem;
  margin: 0;
}
