
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  @include clamp(gap, 2rem, clamp-vw-value(4rem), 4rem);
  display: grid;
}

.title {
  @extend %h3;
  margin: 0;
}

.header {
  @include clamp(gap, 1.5rem, clamp-vw-value(2.5rem), 2.5rem);
  display: grid;
}

.formFields {
  @include clamp(gap, 2rem, clamp-vw-value(4rem), 4rem);
  display: grid;
}

.actions {
  display: grid;
  gap: 2rem;
  justify-items: center;
}
