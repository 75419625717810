
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: var(--cl-space-m);
}

.title {
  @extend %h3;
  margin-bottom: 2.6rem;
}

.description {
  margin-bottom: 0;
  max-width: 78ch;
}

.textLink {
  @extend %textLink;
}

.formField {
  display: grid;
  gap: var(--cl-space-m);
  max-width: 35rem;
}
