
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: var(--cl-space-xl);
}

.title {
  @extend %h3;
  margin-bottom: 2.6rem;
}

.description {
  margin-bottom: 0;
  max-width: 95ch;
}

.formFields {
  display: grid;
  gap: var(--cl-space-l);
}

.formField {
  display: grid;
  gap: 2rem;
  max-width: 35.9rem;
}

.estimatedPropertyValueContainer {
  display: grid;
  gap: 2rem;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
}

.textContainer {
  max-width: 63rem;
}

.fieldset {
  display: contents;
}

.changePropertyValueText {
  @include mq($until: s) {
    display: none;
  }
}
