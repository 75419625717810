
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.headingCell {
  @extend %bodyMedium;
  background: var(--cl-darkGrey);
  color: var(--cl-white);
  font-weight: 600;
  padding: var(--cl-space-m) var(--tableHorizontalPadding);
  text-align: start;
}

.tableHeader {
  @include mq($until: m) {
    display: none;
  }

  th:last-child {
    text-align: end;
  }
}
