
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  display: grid;
  gap: var(--cl-space-xl);
}

.title {
  @extend %h3;
  margin-bottom: 2.6rem;
}

.description {
  margin-bottom: 0;
  max-width: 65ch;
}

.formFields {
  display: grid;
  gap: var(--cl-space-l);
}

.formField {
  display: grid;
  gap: 2rem;
  max-width: 35.9rem;
}

.textContainer {
  max-width: 63rem;
}
