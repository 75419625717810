
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.table {
  --tableHorizontalPadding: var(--cl-space-m);
  width: 100%;

  tr {
    background: var(--cl-lightGrey);
    border-bottom: var(--cl-space-xs) solid var(--cl-white);

    @include mq($until: m) {
      display: block;
    }
  }
}

.headfullTable {
  @include mq($from: m) {
    td:last-child {
      text-align: end;
    }
  }
}

.headlessTable {
  td:last-child {
    font-weight: 600;
    text-align: start;
  }
}
