
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.tableCell {
  @extend %bodyMedium;
  padding: var(--cl-space-m) var(--tableHorizontalPadding);

  @include mq($until: m) {
    display: block;
    line-height: 1.4;
    padding: 0 var(--tableHorizontalPadding) 1.6rem;

    &[data-label]::before {
      content: attr(data-label);
      font-weight: 600;
      margin-right: var(--cl-space-xs);
      vertical-align: text-top;
    }

    &:first-of-type {
      padding-top: var(--cl-space-m);
    }

    &:last-of-type {
      padding-bottom: var(--cl-space-m);
    }
  }
}

.actionCell {
  @include mq($from: m) {
    white-space: nowrap;
    width: 1%;
  }
}

.actionCellInner {
  align-items: center;
  display: flex;
}

.actionCellInnerWithIcon {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.actionLink {
  color: var(--cl-canadaLifeRed);
  font-weight: 600;
}

.disabledLink {
  color: var(--cl-canadaLifeGrey);
  cursor: default;
  pointer-events: none;
}

.linkWrapper {
  display: flex;
  min-width: 9.3rem;

  @include mq($until: m) {
    width: 100%;
  }

  a {
    flex: 1;
  }
}

.delete {
  color: var(--cl-canadaLifeRed);
  display: none;
  font-size: 2.4rem;
  margin-left: var(--cl-space-m);
  vertical-align: middle;

  &:focus {
    box-shadow: 0 0 0 4px var(--cl-brightGold);
    color: var(--cl-darkRed);
    outline: 0;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  @include mq($until: m) {
    position: absolute;
    right: var(--tableHorizontalPadding);
    top: var(--cl-space-m);
  }

  svg {
    display: block;
  }

  &.showForSmall {
    @include mq($until: m) {
      display: block;
    }
  }

  &.showForLarge {
    @include mq($from: m) {
      display: block;
    }
  }
}

.inlineIcon {
  align-items: center;
  display: flex;

  svg {
    margin-right: var(--cl-space-s);
  }
}
