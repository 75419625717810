
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.table {
  --tableHorizontalPadding: var(--cl-space-m);
  border-bottom: 0.4rem solid var(--cl-darkGrey);
  width: 100%;

  tr:nth-child(odd) {
    background-color: var(--cl-lightGrey);
  }

  @include mq($until: m) {
    tr {
      background-color: var(--cl-lightGrey);
      display: block;
      margin-bottom: var(--cl-space-m);
    }
  }
}

.tableHeader {
  @include mq($until: m) {
    display: none;
  }
}

.headingCell {
  @extend %bodyMedium;
  background: var(--cl-darkGrey);
  color: var(--cl-white);
  font-weight: 600;
  padding: var(--cl-space-s) var(--tableHorizontalPadding);
  text-align: start;
}
